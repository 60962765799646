.navbar {
    margin-bottom: 0;
    z-index: 9999;
    border: 0;
    font-size: 12px !important;
    line-height: 1.42857143 !important;
    letter-spacing: 4px;
    border-radius: 0;
  }
  
  .navbar li a, .navbar .navbar-brand {
    color: #000 !important;
  }
  
  .navbar-nav li a:hover, .navbar-nav li.active a {
    color: #f4511e !important;
  }
  
  .navbar-default .navbar-toggle {
    border-color: transparent;
    color: #000 !important;
  }
  
  .nav-item .nav-link {
    text-transform: uppercase;
  }
  
  .nav-link.active {
    color: rgb(231, 29, 115) !important;
  }