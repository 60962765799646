@font-face {
  font-family: 'IBMPlexMono-Light';
  src: url('/IBMPlexMono-Light.ttf');
}
html, body {
  font-family: 'IBMPlexMono-Light'; /*Specify your font name here*/
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

.mg-top-5 {
  margin-top: 5rem!important;
}

.bg-1 {
  background-color: rgb(228, 176, 54);
}

.bg-2 {
  background-color: rgb(231, 29, 115);
}

.bg-3 {
  background-color: rgb(230, 187, 173);
}

.bg-4 {
  background-color: rgb(0, 65, 165);
}

.bg-white {
  background-color: #fff;
}

.bg-grey {
  background-color: #fcfafa;
}

.jumbotron {
  color: rgb(0, 65, 165);
  padding: 100px 25px;
  min-height: 400px;
}

.container-fluid {
  padding: 60px 50px;
}

.center {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .col-sm-6 {
    text-align: center;
    margin: 25px 0;
  }
}

.logo-small {
  color: rgb(231, 29, 115);;
  font-size: 30px;
}

.logo {
  color: #f4511e;
  font-size: 200px;
}

.client-thumbnail {
  padding: 0 0 15px 0;
  border: none;
  border-radius: 0;
}

.client-thumbnail img {
  width: 45%;
  height: 100%;
  margin-bottom: 10px;
}

.slideanim {visibility:hidden;}
.slide {
  /* The name of the animation */
  animation-name: slide;
  -webkit-animation-name: slide;
  /* The duration of the animation */
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  /* Make the element visible */
  visibility: visible;
}

/* Go from 0% to 100% opacity (see-through) and specify the percentage from when to slide in the element along the Y-axis */
@keyframes slide {
  0% {
    opacity: 0;
    transform: translateY(70%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@-webkit-keyframes slide {
  0% {
    opacity: 0;
    -webkit-transform: translateY(70%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0%);
  }
}